
<template>
  <base-section
    id="news"
    space="72"
  >
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          md="6"
        >
          <div
            v-for="(article, i) in articles"
            :key="i"
            :divider="i + 1 < articles.length"
            class="mb-12"
          >
            <base-section
              id="map"
              space="0"
            >
              <video
                id="vid1"
                class="azuremediaplayer amp-default-skin"
                controls
                :poster="article.poster"
                data-setup="{'techOrder': ['azureHtml5JS', 'flashSS', 'html5FairPlayHLS','silverlightSS', 'html5'], 'nativeControlsForTouch': true}"
                width="100%"
              >
                <source
                  :src="article.src"
                  type="application/vnd.ms-sstr+xml"
                >
                <track
                  :src="article.vtt"
                  label="english"
                  kind="subtitles"
                  srclang="en-us"
                >
                <p class="amp-no-js">
                  To view this video please enable JavaScript, and consider upgrading to a web browser that supports HTML5 video
                </p>
              </video>
            </base-section>
            <div class="pa-4">
              <base-title
                :title="article.title"
                class="text-truncate text-uppercase"
              />
              <base-body
                :text="article.text"
                space="0"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>
<script>
  export default {
    name: 'SectionNews',
    data: () => ({
      articles: [
        {
          title: 'For Clients...',
          text: 'With a few easy steps you can get signed up with ScrumOnDemand as a Client and build a team for your projects. Whether you are starting a product from scratch and need a full-fledged team to build out your vision, or you are on the home stretch and need help getting it across the finish line, our diverse community of IT professionals can support your needs.',
          src: 'https://sodmedia-usw22.streaming.media.azure.net/5ba4f274-0048-4d6e-bbbd-5b7c83cc1c05/Discover ScrumOnDemand - Clients.ism/manifest',
          poster: require('@/assets/client-steps-poster.png'),
          vtt: 'https://sodfilestorage.blob.core.windows.net/video-vtt/Discover ScrumOnDemand - Clients.vtt',
        },
        {
          title: 'For Talent...',
          text: 'In just a few steps you can join the Talent community in ScrumOnDemand. Find a team and earn extra money while learning from each other and helping clients complete their projects.',
          src: 'https://sodmedia-usw22.streaming.media.azure.net/fbb89fc5-b3e6-49f9-9352-cb0f870cfed4/Discover ScrumOnDemand - Talents.ism/manifest',
          poster: require('@/assets/talent-steps-poster.png'),
          vtt: 'https://sodfilestorage.blob.core.windows.net/video-vtt/Discover ScrumOnDemand - Talents.vtt',
        },
        // {
        //   icon: 'mdi-text',
        //   date: 'Jul 24, 2019',
        //   category: 'Business Partnerships',
        //   comments: 16,
        //   title: 'Lorem ipsum dolor, sit amet consectetur',
        //   text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero dolorem, eos sapiente, ad voluptatem eveniet, a cum blanditiis consequatur esse facere minima! Non, minus ullam facere earum labore aperiam aliquam.',
        //   src: require('@/assets/article-4.jpg'),
        // },
        // {
        //   icon: 'mdi-text',
        //   date: 'May 4, 2019',
        //   category: 'Analytics Implementation',
        //   comments: 5,
        //   title: 'Lorem ipsum dolor, sit amet consectetur',
        //   text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero dolorem, eos sapiente, ad voluptatem eveniet, a cum blanditiis consequatur esse facere minima! Non, minus ullam facere earum labore aperiam aliquam. ',
        //   src: require('@/assets/article-2.jpg'),
        // },
      ],
    }),
    mounted () {
      const azureVideoScript = document.createElement('script')
      azureVideoScript.setAttribute('src', 'https://amp.azure.net/libs/amp/latest/azuremediaplayer.min.js')
      document.head.appendChild(azureVideoScript)
    },
  }
</script>
